<template>
    <div class="ship-info-container">
        <div class="button-section">
            <!-- <Button type="success" class="btn">新增</Button>
            <Button type="warning" class="btn">修改</Button>
            <Button type="error" class="btn">删除</Button>-->
            <div class="search-item">
                <span class="search-title">时间段：</span>
                <Date-picker format="yyyy-MM-dd HH:mm:ss" v-model="searchDateArr" type="datetimerange"
                    placement="bottom-end" placeholder="选择日期" style="width: 250px"></Date-picker>
            </div>
            <Button @click="searchHandler" style="margin-left: 20px" type="success" class="btn">搜索</Button>
            <Button @click="resetHandler" style="margin-left: 20px" type="default" class="btn">重置</Button>
        </div>
        <Table :loading="loading" :data="messageList" :columns="columns"></Table>
        <Page @on-change="pageChange" @on-page-size-change="pageSizeChange" :total="total" size="small" show-total
            show-elevator show-sizer style="margin-top: 20px"></Page>
    </div>
</template>

<script>
import { getWarningInfoList } from "../../utils/api";
import { getDateByDatePicker } from "../../utils/util";

export default {
    data() {
        return {
            //查询数据部分
            messageList: [], //表格数据
            loading: false, //是否处于加载
            total: 0, //总数
            columns: [
                {
                    title: "序号",
                    key: "num",
                    width: 70,
                },
                {
                    title: "消息内容",
                    key: "pushContent",
                    minWidth: 200,
                },
                {
                    title: "手机号",
                    key: "phones",
                    width: 150,
                },
                {
                    title: "备注",
                    key: "remark",
                    width: 150,
                },
                // {
                //   title: "操作",
                //   key: "action",
                //   width: 100,
                //   render: (h, params) => {
                //     return h("div", { class: "can-click" }, [
                //       h(
                //         "span",
                //         {
                //           on: {
                //             click: () => {
                //               this.modifyBanner(params.row);
                //             },
                //           },
                //         },
                //         "修改"
                //       ),
                //       h(
                //         "span",
                //         {
                //           on: {
                //             click: () => {
                //               this.modifyBanner(params.row);
                //             },
                //           },
                //         },
                //         "删除"
                //       ),
                //     ]);
                //   },
                // },
            ],
            pageSize: 10,
            pageNum: 1,
            searchDateArr: [],
        };
    },
    created() {
        this.getWarningInfoListAction();
    },
    methods: {
        // 查询方法
        resetHandler() {
            this.pageSize = 10;
            this.pageNum = 1;
            this.searchDateArr = [];
            this.getWarningInfoListAction();
        },
        searchHandler() {
            this.getWarningInfoListAction();
        },
        getWarningInfoListAction() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                warnPushRecordDTO: {},
            };
            if (this.searchDateArr.length && this.searchDateArr[0] != "") {
                params.warnPushRecordDTO.startTime = getDateByDatePicker(this.searchDateArr[0]);
                params.warnPushRecordDTO.endTime = getDateByDatePicker(this.searchDateArr[1]);
            }
            getWarningInfoList(params).then((res) => {
                if (res.code == 0) {
                    this.total = res.data.total;
                    let list = res.data.list;
                    list.forEach((item, index) => {
                        item.num = index + 1; //加序号
                    });
                    this.messageList = list;
                }
            });
        },

        pageChange(num) {
            this.pageNum = num;
            this.getWarningInfoListAction();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getWarningInfoListAction();
        },
    },
};
</script>

<style lang="scss">
.ship-info-container {
    height: 100%;
    padding: 30px;
    text-align: center;
    overflow-y: auto;

    .button-section {
        display: flex;
        padding-bottom: 20px;

        .search-title {
            font-size: 16px;
            color: #fff;
        }

        .btn {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
</style>
